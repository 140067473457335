/* .fc-highlight {
	background-color: blueviolet;
	opacity: .3;
} */

/* remove background color of "today" column in V4 */
/* .fc-today {
	background-color: inherit !important;
} */

/* (override default fullcalendar styles) custom event style on creating */
.fc-event {
	background-color: blueviolet;
	border-color: blueviolet;
	opacity: 0.5;
}

/* Selected events highligh style (moved it to MUI styles to avoid potiential class name conflicts) */
/* .selected {
	box-shadow: 0px 0px 5px 2px #000;
	padding: 3px 0px 3px 3px;
	margin: 5px 1px 3px 0px;
	border: 2px solid #000;
} */

/* (override default fullcalendar styles) remove default event mirror */
.fc-timegrid-event.fc-event-mirror {
	box-shadow: none;
	border: none;
	background: none;
}
.fc-timegrid-bg-harness > .fc-highlight {
	background: none;
}

/* (override default fullcalendar styles) Remove highlight background color of "today" column */
.fc .fc-timegrid-col.fc-day-today {
	background-color: inherit;
}
